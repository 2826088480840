import { getRoutePathWithStudyPeriod, ROUTE_PATH } from '../../../../constants/route_paths';
import { STUDY_PERIOD } from '../../../../constants/study_period';
import { ENTITY_ID, ENTITY_LABELS } from '../../../../../redux/reducers/entityIdReducers';
import { DEFAULT_BUSINESS_ID, DEFAULT_ENVIRONMENT } from '../../../../config/api_constants';
import { STUDY_TYPE } from '../../../../../redux/reducers/studyTypeReducers';

export const SIGN_OUT_HREF = '#/signout';

export const getNavBarHeaderConfig = (studyPeriod, clickable = false) => {
  return {
    text: 'Radical',
    href: clickable ? getRoutePathWithStudyPeriod(ROUTE_PATH.STUDY_SUMMARY, studyPeriod) : '',
  };
};

const getStudyPeriodPath = (studyPeriod, parentRoute) => {
  return parentRoute === undefined ? studyPeriod : `${parentRoute}/${studyPeriod}`;
};

const getItemsForStudyPeriod = (studyPeriod, parentRoute = undefined) => {
  const studyPeriodPath = getStudyPeriodPath(studyPeriod, parentRoute);
  return [
    {
      type: 'link',
      text: 'QNQ',
      href: getRoutePathWithStudyPeriod(ROUTE_PATH.QNQ_PAGE, studyPeriodPath),
    },
    {
      type: 'link-group',
      text: 'Population',
      href: getRoutePathWithStudyPeriod(ROUTE_PATH.POPULATION_PAGE, studyPeriodPath),
      items: [
        {
          type: 'link',
          text: 'Grouping',
          href: getRoutePathWithStudyPeriod(ROUTE_PATH.GROUPING_PAGE, studyPeriodPath),
        },
      ],
    },
    {
      type: 'link',
      text: 'Surveys',
      href: getRoutePathWithStudyPeriod(ROUTE_PATH.SURVEY_PAGE, studyPeriodPath),
    },
    {
      type: 'link',
      text: 'QRE',
      href: getRoutePathWithStudyPeriod(ROUTE_PATH.QRE_PAGE, studyPeriodPath),
    },
    {
      type: 'link',
      text: 'Notification',
      href: getRoutePathWithStudyPeriod(ROUTE_PATH.NOTIFICATION_PAGE, studyPeriodPath),
    },
    {
      type: 'link',
      text: 'Analytics',
      href: getRoutePathWithStudyPeriod(ROUTE_PATH.ANALYTICS_PAGE, studyPeriodPath),
    },
  ];
};

const getItemsForUSDualAllocationStudyPeriod = (studyPeriod, parentRoute = undefined) => {
  const studyPeriodPath = getStudyPeriodPath(studyPeriod, parentRoute);
  return [
    {
      type: 'link',
      text: 'Business components',
      href: getRoutePathWithStudyPeriod(ROUTE_PATH.BUSINESS_COMPONENT_PAGE, studyPeriodPath),
    },
    {
      type: 'link',
      text: 'Population',
      href: getRoutePathWithStudyPeriod(ROUTE_PATH.POPULATION_PAGE, studyPeriodPath),
    },
    {
      type: 'link',
      text: 'Surveys',
      href: getRoutePathWithStudyPeriod(ROUTE_PATH.SURVEY_PAGE, studyPeriodPath),
    },
    {
      type: 'link',
      text: 'QRE',
      href: getRoutePathWithStudyPeriod(ROUTE_PATH.QRE_PAGE, studyPeriodPath),
    },
    {
      type: 'link',
      text: 'Analytics',
      href: getRoutePathWithStudyPeriod(ROUTE_PATH.ANALYTICS_PAGE, studyPeriodPath),
    },
  ];
};

function getNavbarItemsForStudyPeriod(studyPeriod, parentRoute = undefined) {
  return {
    type: 'expandable-link-group',
    href: getRoutePathWithStudyPeriod(
      ROUTE_PATH.STUDY_SUMMARY,
      parentRoute === undefined ? studyPeriod : `${parentRoute}/${studyPeriod}`,
    ),
    text:
      STUDY_TYPE.US_DUAL_ALLOCATION === parentRoute
        ? `Study Period ${studyPeriod} Dual Allocation`
        : `Study Period ${studyPeriod}`,
    items:
      STUDY_TYPE.US_DUAL_ALLOCATION === parentRoute
        ? getItemsForUSDualAllocationStudyPeriod(studyPeriod, parentRoute)
        : getItemsForStudyPeriod(studyPeriod, parentRoute),
  };
}

function getNavbarItemsForEntityId(entityId, taxYears) {
  return {
    type: 'expandable-link-group',
    href: '',
    text: `${ENTITY_LABELS[entityId] || entityId}`,
    items: taxYears.map(taxYear =>
      getNavbarItemsForStudyPeriod(
        STUDY_PERIOD[taxYear],
        `${STUDY_TYPE.UK_DUAL_ALLOCATION}/${entityId}`,
      ),
    ),
  };
}

const BUSINESS_CLIENT_ID_TO_NAV_BAR_CONFIG = {
  RadicalUK: [
    getNavbarItemsForEntityId(ENTITY_ID.ADCL, [2024]),
    { type: 'divider' },
    getNavbarItemsForEntityId(ENTITY_ID.ADCS, [2024]),
    { type: 'divider' },
    getNavbarItemsForEntityId(ENTITY_ID.ET, [2024]),
    { type: 'divider' },
    getNavbarItemsForEntityId(ENTITY_ID.IMDBUK, [2024]),
    { type: 'divider' },
    getNavbarItemsForEntityId(ENTITY_ID.VEEQO, [2024]),
  ],
  Radical: [
    getNavbarItemsForStudyPeriod(STUDY_PERIOD[2024], STUDY_TYPE.US_DUAL_ALLOCATION),
    { type: 'divider' },
    getNavbarItemsForStudyPeriod(STUDY_PERIOD[2024], STUDY_TYPE.US_SINGLE_ALLOCATION),
    { type: 'divider' },
    getNavbarItemsForStudyPeriod(STUDY_PERIOD[2023], STUDY_TYPE.US_SINGLE_ALLOCATION),
    { type: 'divider' },
    getNavbarItemsForStudyPeriod(STUDY_PERIOD[2022], STUDY_TYPE.US_SINGLE_ALLOCATION),
    { type: 'divider' },
    getNavbarItemsForStudyPeriod(STUDY_PERIOD[2021], STUDY_TYPE.US_SINGLE_ALLOCATION),
    ...(DEFAULT_ENVIRONMENT !== 'PRODUCTION'
      ? [
          { type: 'divider' },
          getNavbarItemsForStudyPeriod(STUDY_PERIOD[2199], STUDY_TYPE.US_SINGLE_ALLOCATION),
        ]
      : []),
  ],
};

export const NAVBAR_ITEMS_CONFIG = [
  ...BUSINESS_CLIENT_ID_TO_NAV_BAR_CONFIG[DEFAULT_BUSINESS_ID],
  // {
  //   type: 'expandable-link-group',
  //   text: 'Templates',
  //   items: [
  //     {
  //       type: 'link',
  //       text: 'Survey',
  //     },
  //     {
  //       type: 'link',
  //       text: 'Notification',
  //     },
  //     { type: 'divider' },
  //     {
  //       type: 'link',
  //       text: 'User Permission',
  //     },
  //   ],
  // },
  { type: 'divider' },
  {
    type: 'link',
    text: 'Sign out',
    href: SIGN_OUT_HREF,
  },
];
