import React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import QRETable from './qre_table/components/QRETable';

const LaborTab = ({ qreTypeFromState }) => {
  return <QRETable qreType={qreTypeFromState} />;
};

LaborTab.propTypes = {
  qreTypeFromState: PropTypes.string, // Comes from Redux state
};

// Map Redux state to the qreType prop
const mapStateToProps = state => ({
  qreTypeFromState: get(state, 'studyType.qreClassification'),
});

export default connect(mapStateToProps)(LaborTab);
