/* eslint-disable import/prefer-default-export */
import * as React from 'react';
import { DEFAULT_TABLE_PAGE_SIZE } from '../../../../common/components/table/constants';

import QRETableActions from './QRETableActions';
import { QRE_CLASSIFICATION } from '../../constants/qre_constants';

const EmptyTableView = () => <p>No qre data.</p>;

function getHeaderConfig(qreType) {
  switch (qreType) {
    case QRE_CLASSIFICATION.LABOR:
    case QRE_CLASSIFICATION.LABOR_DUAL:
    default:
      return {
        title: 'Employees',
        Actions: QRETableActions,
        qreType,
      };

    case QRE_CLASSIFICATION.CONTRACT:
      return {
        title: 'Vendors',
        Actions: QRETableActions,
        qreType,
      };

    case QRE_CLASSIFICATION.SUPPLIES:
      return {
        title: 'Supplies',
        Actions: QRETableActions,
        qreType,
      };
  }
}

export const getQRETableConfig = qreType => {
  const headerConfig = getHeaderConfig(qreType);
  return {
    dataTrackingId: 'qreId',
    dataLoadingText: 'Loading qre data',
    emptyElement: EmptyTableView,
    pageSize: DEFAULT_TABLE_PAGE_SIZE,
    multiSelectConfig: qreType !== QRE_CLASSIFICATION.SUPPLIES ? { selectionType: 'multi' } : {},
    headerConfig,
    getDataRequestBody: () => {
      return {
        qreType,
      };
    },
  };
};
