export const DEFAULT_BUSINESS_ID = process.env.REACT_APP_CLIENT_ID;
export const DEFAULT_COUNTRY = process.env.REACT_APP_DEFAULT_COUNTRY;
export const DEFAULT_TIMEZONE = process.env.REACT_APP_TIMEZONE;
export const DEFAULT_TIMEZONE_ABBREVIATION = process.env.REACT_APP_TIMEZONE_ABBREVIATION;
export const DEFAULT_STUDY_TYPE = process.env.REACT_APP_DEFAULT_STUDY_TYPE;
export const DEFAULT_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const ITEM_TYPE_LABOR = 'labor';
export const ITEM_TYPE_CONTRACT = 'contractresearch';
export const ITEM_TYPE_SUPPLIES = 'supplies';
export const BC_SEARCH_TYPE_BUSINESS_COMPONENT = 'BusinessComponent';
export const BC_SEARCH_TYPE_PROJECT_MAPPING = 'PROJECT_MAPPING';
