import React from 'react';
import Flashbar from '@amzn/awsui-components-react/polaris/flashbar';
import { cloneDeep, get, isNull } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { UPDATE_LABOR_GROUPING_ACTION_TYPES } from '../../../../app/grouping/components/labor_tab/components/labor_table/redux/updateLaborGrouping';
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_INFO,
  NOTIFICATION_TYPE_SUCCESS,
} from './constants';
import { OVERRIDE_LABOR_GROUPING_ACTION_TYPES } from '../../../../app/grouping/components/labor_tab/components/labor_table/redux/overrideLaborGrouping';
import { CONFIGURE_SURVEYS_ACTION_TYPES } from '../../../../app/survey/components/redux/surveyConfiguration';
import { UPDATE_CONTRACT_GROUPING_ACTION_TYPES } from '../../../../app/grouping/components/contract_tab/components/contract_table/redux/updateContractGrouping';
import { OVERRIDE_CONTRACT_GROUPING_ACTION_TYPES } from '../../../../app/grouping/components/contract_tab/components/contract_table/redux/overrideContractGrouping';
import { UPDATE_SURVEY_ACTION_TYPES } from '../../../../app/survey/redux/UpdateSurveys';
import { EXPORT_DATA_ACTION_TYPES } from '../../../redux/export';
import { UPDATE_JOB_CODES_QNQ_ACTION_TYPES } from '../../../../app/qnq/components/job_codes_tab/components/job_codes_table/redux/updateJobCodesQnq';
import { UPDATE_COST_CENTERS_QNQ_ACTION_TYPES } from '../../../../app/qnq/components/cost_centers_tab/components/costs_table/redux/updateCostCentersQnq';
import { UPDATE_PROJECTS_QNQ_ACTION_TYPES } from '../../../../app/qnq/components/projects_tab/components/projects_table/redux/updateProjectsQnq';
import { TRIGGER_REPORT_REFRESH_ACTION_TYPES } from '../../../../app/analytics/components/report_table/redux/TriggerReportRefresh';
import { TRIGGER_IMPORT_ACTION_TYPES } from '../../../../app/import_data/redux/TriggerImport';
import { CONFIGURE_NOTIFICATION_ACTION_TYPES } from '../../../../app/notification/configure_notification/redux/configure-notification-slice';
import { DELETE_NOTIFICATION_ACTION_TYPES } from '../../../../app/notification/Notifications/components/Overview/redux/deleteNotifications';
import { CREATE_NOTIFICATION_SCHEDULED_ACTION_TYPES } from '../../../../app/notification/Notifications/components/Overview/redux/createNotificationSchedule';
import { CANCEL_SCHEDULED_NOTIFICATION_ACTION_TYPES } from '../../../../app/notification/Notifications/components/Overview/redux/cancelScheduledNotification';

export const getNotificationFlash = (notification, clearPageNotificationHandler) => {
  if (!notification || !clearPageNotificationHandler) {
    return <div />;
  }

  const { type, content } = notification;
  if (!type || !content) {
    return <div />;
  }

  const action = get(notification, 'action', null);
  const flashItems = [
    {
      type,
      content,
      action,
      dismissible: true,
      onDismiss: clearPageNotificationHandler,
    },
  ];

  return <Flashbar key={Math.random()} items={flashItems} />;
};

export const getBanner = message => {
  if (!message) {
    return <div />;
  }
  return <h1 className="awsui-util-t-c">{message}</h1>;
};

export const notificationActionHandler = action => {
  const status = get(action, 'payload.status', '');
  switch (action.type) {
    case UPDATE_CONTRACT_GROUPING_ACTION_TYPES.SUCCESS:
    case UPDATE_LABOR_GROUPING_ACTION_TYPES.SUCCESS: {
      const type = status === 'Success' ? NOTIFICATION_TYPE_SUCCESS : NOTIFICATION_TYPE_ERROR;
      const content =
        status === 'Success' ? `Update all request has been successfully scheduled` : status;
      return {
        type,
        content,
        id: uuidv4(),
      };
    }
    case OVERRIDE_CONTRACT_GROUPING_ACTION_TYPES.SUCCESS:
    case OVERRIDE_LABOR_GROUPING_ACTION_TYPES.SUCCESS: {
      const type = status === 200 ? NOTIFICATION_TYPE_SUCCESS : NOTIFICATION_TYPE_ERROR;
      const content = get(action, 'payload.statusMessage');
      return {
        type,
        content,
        id: uuidv4(),
      };
    }
    case CONFIGURE_SURVEYS_ACTION_TYPES.SUCCESS: {
      const type = status === 200 ? NOTIFICATION_TYPE_SUCCESS : NOTIFICATION_TYPE_ERROR;
      let content = '';
      switch (status) {
        case 200: {
          let addMessage = '';
          const groupingId = get(action, 'payload.body.surveyGroupId');
          if (
            groupingId
              .toString()
              .toLowerCase()
              .endsWith('labor')
          )
            addMessage = ' for labor';
          else if (
            groupingId
              .toString()
              .toLowerCase()
              .endsWith('contract')
          )
            addMessage = ' for contract';
          content = `Create Survey(s) request${addMessage} has been submitted successfully.`;
          break;
        }
        default:
          content = get(action, 'payload.statusMessage');
      }

      return {
        type,
        content,
        id: uuidv4(),
      };
    }
    case UPDATE_SURVEY_ACTION_TYPES.SUCCESS: {
      const type = status === 200 ? NOTIFICATION_TYPE_SUCCESS : NOTIFICATION_TYPE_ERROR;
      const content =
        status === 200
          ? 'Survey(s) has been updated successfully.'
          : 'An error occurred while updating surveys';
      return {
        type,
        content,
        id: uuidv4(),
      };
    }
    case EXPORT_DATA_ACTION_TYPES.SUCCESS: {
      const exportId = get(action, 'payload.exportId', null);
      let type;
      let content;
      if (isNull(exportId)) {
        type = NOTIFICATION_TYPE_ERROR;
        content = get(action, 'payload.message', null);
        if (isNull(content)) content = 'Data export failed';
      } else {
        type = NOTIFICATION_TYPE_SUCCESS;
        content = 'Data export is scheduled successfully.';
      }
      return {
        type,
        content,
        id: uuidv4(),
      };
    }
    case UPDATE_JOB_CODES_QNQ_ACTION_TYPES.SUCCESS: {
      const type = status === 200 ? NOTIFICATION_TYPE_SUCCESS : NOTIFICATION_TYPE_ERROR;
      const content =
        status === 200
          ? 'Job codes(s) has been updated.'
          : 'An error occurred while updating job code(s).';
      return {
        type,
        content,
        id: uuidv4(),
      };
    }
    case UPDATE_COST_CENTERS_QNQ_ACTION_TYPES.SUCCESS: {
      const type = status === 200 ? NOTIFICATION_TYPE_SUCCESS : NOTIFICATION_TYPE_ERROR;
      const content =
        status === 200
          ? 'Cost center(s) has been updated.'
          : 'An error occurred while updating cost center(s).';
      return {
        type,
        content,
        id: uuidv4(),
      };
    }
    case UPDATE_PROJECTS_QNQ_ACTION_TYPES.SUCCESS: {
      const type = status === 200 ? NOTIFICATION_TYPE_SUCCESS : NOTIFICATION_TYPE_ERROR;
      const content =
        status === 200
          ? 'Project(s) has been updated.'
          : get(action, 'payload.statusMessage', 'An error occurred while updating project(s).');
      return {
        type,
        content,
        id: uuidv4(),
      };
    }
    case TRIGGER_REPORT_REFRESH_ACTION_TYPES.SUCCESS: {
      const type = status === 200 ? NOTIFICATION_TYPE_SUCCESS : NOTIFICATION_TYPE_ERROR;
      const content =
        status === 200
          ? 'Report refresh is scheduled successfully.'
          : get(action, 'payload.statusMessage', 'Report refresh failed.');
      return {
        type,
        content,
        id: uuidv4(),
      };
    }
    case TRIGGER_IMPORT_ACTION_TYPES.FAILURE:
    case TRIGGER_IMPORT_ACTION_TYPES.SUCCESS: {
      const isTriggerSuccessful = get(action, 'payload.isTriggered', false);
      const type =
        status === 200 && isTriggerSuccessful ? NOTIFICATION_TYPE_SUCCESS : NOTIFICATION_TYPE_ERROR;
      const content =
        status === 200 && isTriggerSuccessful ? 'Import is in progress.' : 'Import failed.';
      return {
        type,
        content,
        id: uuidv4(),
      };
    }
    case CONFIGURE_NOTIFICATION_ACTION_TYPES.FAILURE:
    case CONFIGURE_NOTIFICATION_ACTION_TYPES.SUCCESS: {
      const type = status === 200 ? NOTIFICATION_TYPE_SUCCESS : NOTIFICATION_TYPE_ERROR;
      const content =
        status === 200
          ? 'Notification is created successfully.'
          : get(action, 'payload.statusMessage', '');
      return {
        type,
        content,
        id: uuidv4(),
      };
    }
    case DELETE_NOTIFICATION_ACTION_TYPES.FAILURE:
    case DELETE_NOTIFICATION_ACTION_TYPES.SUCCESS: {
      const type = status === 200 ? NOTIFICATION_TYPE_INFO : NOTIFICATION_TYPE_ERROR;
      const content =
        status === 200
          ? 'Notification is deleted successfully.'
          : get(action, 'payload.statusMessage', '');
      return {
        type,
        content,
        id: uuidv4(),
      };
    }
    case CREATE_NOTIFICATION_SCHEDULED_ACTION_TYPES.FAILURE:
    case CREATE_NOTIFICATION_SCHEDULED_ACTION_TYPES.SUCCESS: {
      const type = status === 200 ? NOTIFICATION_TYPE_SUCCESS : NOTIFICATION_TYPE_ERROR;
      const content =
        status === 200
          ? 'You have successfully scheduled the email.' +
            " To change anything, click 'Cancel schedule delivery' first."
          : get(action, 'payload.statusMessage', '');
      return {
        type,
        content,
        id: uuidv4(),
      };
    }
    case CANCEL_SCHEDULED_NOTIFICATION_ACTION_TYPES.FAILURE:
    case CANCEL_SCHEDULED_NOTIFICATION_ACTION_TYPES.SUCCESS: {
      const type = status === 200 ? NOTIFICATION_TYPE_INFO : NOTIFICATION_TYPE_ERROR;
      const content =
        status === 200
          ? 'You have cancelled the scheduled delivery.'
          : get(action, 'payload.statusMessage', '');
      return {
        type,
        content,
        id: uuidv4(),
      };
    }
    default:
      return null;
  }
};

export const addOrUpdateNotification = (state, action) => {
  const stateClone = cloneDeep(state);

  const newNotification = get(action, 'payload', null);
  const newNotificationId = get(newNotification, 'id', null);
  const existingNotificationIndex = stateClone.findIndex(notification => {
    return notification.id === newNotificationId;
  });

  if (existingNotificationIndex === -1) {
    // Add notification
    return [...state, newNotification];
  }

  // Update notification
  stateClone.splice(existingNotificationIndex, 1, newNotification);
  return stateClone;
};
