import React from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { SurveyVendorListTable } from './SurveyVendorListTable';

const styles = StyleSheet.create({
  section: {
    fontSize: 10,
    paddingLeft: 7,
    color: '#8c8c8c',
  },
  hyperLinkText: {
    color: '#007399',
  },
  subSection: {
    paddingTop: 8,
    fontSize: 10,
    paddingLeft: 7,
    color: '#8c8c8c',
  },
});

export function SurveyVendorSection({ data }) {
  const { section, hyperLinkText, subSection } = styles;
  const vendorData = get(data, 'vendorData', []);

  const issueLink =
    'https://issues.amazon.com/issues/create?template=6c5aeeab-46c2-4b2c-aa3d-fe6fce6004a4';
  return (
    <View>
      <Text style={section}>
        Review the vendor(s) assigned to your survey. You will be asked to enter the percentage of
        time each vendor spent on various projects and activities in {data.studyPeriod}.
      </Text>
      <SurveyVendorListTable vendorData={vendorData} />
      <Text style={subSection}>
        Need to add or remove a vendor expense from your survey? Request through{' '}
        <Text style={hyperLinkText} src={issueLink}>
          SIM
        </Text>{' '}
        or contact{' '}
        <Text style={hyperLinkText} src={`mailto:${process.env.REACT_APP_CONTACT_US_MAIL}`}>
          {process.env.REACT_APP_CONTACT_US_MAIL}
        </Text>{' '}
      </Text>
    </View>
  );
}

SurveyVendorSection.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SurveyVendorSection;
