import { DEFAULT_STUDY_TYPE } from '../../../common/config/api_constants';
import { SURVEY_TYPE } from '../../../common/components/pdf_generator/templates/SurveyPDFTemplate/utils/DataUtils';
import { QRE_CLASSIFICATION } from '../../../app/qre/constants/qre_constants';

export const STUDY_TYPE = {
  US_SINGLE_ALLOCATION: 'US_SINGLE_ALLOCATION',
  US_DUAL_ALLOCATION: 'US_DUAL_ALLOCATION',
  UK_DUAL_ALLOCATION: 'UK_DUAL_ALLOCATION', // This is defaulted in the UK
};

const getSurveyType = studyType => {
  return [STUDY_TYPE.US_SINGLE_ALLOCATION, STUDY_TYPE.UK_DUAL_ALLOCATION].includes(studyType)
    ? SURVEY_TYPE.LABOR
    : SURVEY_TYPE.LABOR_DUAL;
};

const getQREClassification = studyType => {
  return [STUDY_TYPE.US_SINGLE_ALLOCATION, STUDY_TYPE.UK_DUAL_ALLOCATION].includes(studyType)
    ? QRE_CLASSIFICATION.LABOR
    : QRE_CLASSIFICATION.LABOR_DUAL;
};

const initialState = {
  studyType: DEFAULT_STUDY_TYPE,
  surveyType: getSurveyType(DEFAULT_STUDY_TYPE),
  qreClassification: getQREClassification(DEFAULT_STUDY_TYPE),
};

const studyTypeReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_STUDY_TYPE': {
      const { studyType } = action.payload;

      let surveyType;
      let qreClassification;
      switch (studyType) {
        case STUDY_TYPE.US_SINGLE_ALLOCATION:
          surveyType = SURVEY_TYPE.LABOR;
          qreClassification = QRE_CLASSIFICATION.LABOR;
          break;
        case STUDY_TYPE.US_DUAL_ALLOCATION:
          surveyType = SURVEY_TYPE.LABOR_DUAL;
          qreClassification = QRE_CLASSIFICATION.LABOR_DUAL;
          break;
        case STUDY_TYPE.UK_DUAL_ALLOCATION:
          surveyType = SURVEY_TYPE.LABOR;
          qreClassification = QRE_CLASSIFICATION.LABOR;
          break;
        default:
          surveyType = undefined;
      }

      return { ...state, studyType, surveyType, qreClassification };
    }
    default:
      return state;
  }
};

export default studyTypeReducer;
