import { createMatchSelector } from 'connected-react-router';
import { getRoutePathWithStudyPeriodPlaceholder } from './route_paths';
import { DEFAULT_ENVIRONMENT } from '../config/api_constants';

export const STUDY_PERIOD = {
  2021: '2021',
  2022: '2022',
  2023: '2023',
  2024: '2024',
  2199: '2199',
};

export const CURRENT_STUDY_PERIOD = STUDY_PERIOD[2024];

export const isActionDisabledForStudyPeriod = studyPeriod => {
  if (DEFAULT_ENVIRONMENT === 'PRODUCTION') {
    return studyPeriod !== CURRENT_STUDY_PERIOD;
  }
  return false;
};

export const getStudyPeriod = state => {
  const matchSelector = createMatchSelector({
    path: getRoutePathWithStudyPeriodPlaceholder('/:routePath'),
  });
  const match = matchSelector(state);
  return match.params.studyPeriod;
};
